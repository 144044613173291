import React from 'react'
import './modal.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'

export default class Modal extends React.Component {
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };

    render() {
        if(!this.props.show){
            return null;
        }
        return <div id="Modal">
            <div className="modal-overlay"/>
            <div className="modal-content">
                <div className="modal-actions">
                    <div>
                        <h3>{this.props.title}</h3>
                    </div>
                    <div>
                        <FontAwesomeIcon onClick={this.onClose} icon={faTimes} />
                    </div>
                </div>
                <div className="content">{this.props.children}</div>
            </div>
        </div>;
    }
}
