import React, { Component } from "react";
import './titleCards.scss'

export class TitleCards extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            title1,
            title2,
            title3,
            title4
        } = this.props;

        return (
            <div id="TitleCards">
                <div><h4>{title1}</h4></div>
                <div><h4>{title2}</h4></div>
                <div><h4>{title3}</h4></div>
                <div><h4>{title4}</h4></div>
            </div>
        )
    }
}
