import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import Helmet from 'react-helmet'
// import Auth from '../../app/auth'

import {firebaseAuth, firebaseDatabase} from '../../app/firebase-utils'
import { toast } from 'react-toastify';

import { Input } from '../../components/input/input'
import { Loading } from '../../components/loading/loading'
import { Button } from '../../components/button/button'
import { ImgScreen } from '../../components/imgScreen/imgScreen'

import "./login.scss"

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.header = {
            title: "Login"
        };
        this.state = {
            email: '',
            password: '',
            loading: false
        }
    }

    handleChange = (event) => {
        const name = event.target.name,
            value = event.target.value;
        this.setState({[name]: value});
    };

    handleSubmit = (event) => {
        this.setState({loading: true});

        if(!this.state.email && !this.state.password){
            toast.error('⚠ Digite suas credenciais!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.setState({loading: false});
            return
        }

        firebaseAuth.signInWithEmailAndPassword(this.state.email, this.state.password)
            .then(cred => {
                localStorage.setItem('user-cred', JSON.stringify(cred));
                localStorage.setItem('uid', JSON.stringify(cred.user.uid));
                //Auth.login();
                this.checkRole(cred.user.uid)
            })
            .catch(error => {
                this.setState({loading: false});

                if(error.code === 'auth/wrong-password') {
                    // console.log('password not found');
                    toast.error('🚫 Senha errada!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    //senha errada
                }

                if(error.code === 'auth/user-not-found') {
                    // console.log('user not found');
                    toast.error('🚫 Usuário não encontrado!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    //user not found
                }
            });
        event.preventDefault();
    };

    checkRole = (userUid) => {
        let docRef = firebaseDatabase.collection('users');

        docRef.where("uid", "==", userUid).get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log(doc.data());
                    this.setState({loading: false});

                    if(doc.data().blocked) {
                        localStorage.clear();
                        return toast.error('🚫 Usuário bloqueado!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    }



                    if(doc.data().firstLogin) {
                        console.log('working');
                        localStorage.setItem('user-role', doc.data().role);
                        localStorage.setItem('user-info', JSON.stringify(doc.data()));
                        return this.props.history.push('/password/reset');
                    }

                    if(doc.data().role === 'admin') {
                        localStorage.setItem('user-role', doc.data().role);
                        localStorage.setItem('user-info', JSON.stringify(doc.data()));
                        this.props.history.push('/dashboard/admin');
                    }

                    if(doc.data().role === 'func' || doc.data().role === 'cli') {
                        localStorage.setItem('user-role', doc.data().role);
                        localStorage.setItem('user-info', JSON.stringify(doc.data()));
                        this.props.history.push('/dashboard/drive');
                    }
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    };

    render() {
        return (
                <Fragment>
                    <Helmet title={this.header.title}/>
                    {this.state.loading? <Loading/> : null}
                    <ImgScreen>
                        <form id={"LoginForm"} onSubmit={this.handleSubmit}>
                            <Input inputColor={'inputGrey'} type={'email'} name={'email'} placeholder={'Email'} onChange={this.handleChange}/>
                            <Input inputColor={'inputGrey'} type={'password'} name={'password'} placeholder={'Password'} minlength={'6'} onChange={this.handleChange}/>
                            <div><Button name={'login'} type={'submit'} buttonColor={'primary'}/></div>
                            <div><Link to={'/forgot-password'}><Button name={'Esqueceu a senha?'} buttonColor={'text'}/></Link></div>
                        </form>
                    </ImgScreen>
                </Fragment>
        )
    }
}
