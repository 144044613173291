import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import Helmet from 'react-helmet'
import {firebaseAuth, firebaseDatabase} from '../../app/firebase-utils'

import {Input} from '../../components/input/input'
import {Button} from '../../components/button/button'
import {ImgScreen} from "../../components/imgScreen/imgScreen";

import "./newPassword.scss"
import {toast} from "react-toastify";
import {Loading} from "../../components/loading/loading";

export default class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.header = {
            title: "Nova Senha"
        };
        this.state = {
            userLogged: JSON.parse(localStorage.getItem('user-info')),
            loading: false,
            password: '',
            confirmPassword: ''
        }
    }

    handleChange = (event) => {
        const name = event.target.name,
            value = event.target.value;
        this.setState({[name]: value});
    };

    createNewPassword = (e) => {
        this.setState({loading: true});
        if(this.state.password === this.state.confirmPassword) {
            firebaseAuth.currentUser.updatePassword(this.state.password)
                .then(() => {
                    this.getUserToEdit()
                })
                .catch((error) => {
                    this.setState({loading: false});
                    console.log(error);
                    return toast.error('Ops! Algo de errado aconteceu', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                });
        }
        e.preventDefault();
    };

    getUserToEdit = () => {
        firebaseDatabase.collection('users').where('uid', '==', this.state.userLogged.uid).get()
            .then(querySnapshot => {
                querySnapshot.forEach(obj => {
                    // console.log(obj.id);
                    this.editUser(obj.id)
                })
            })
            .catch(e => {
                this.setState({loading: false});
                console.log(e)
            });
    };

    editUser = (id) => {
        firebaseDatabase.collection('users').doc(id).update({firstLogin: false})
            .then(o => {
                this.setState({loading: false});
                this.props.history.push('/dashboard/drive');
            })
            .catch(e => {
                this.setState({loading: false});
                console.log(e)
            })
    }

    render() {
        return (
            <Fragment id="gridLoginContainer">
                <Helmet title={this.header.title}/>
                {this.state.loading ? <Loading/> : null}
                <ImgScreen>
                    <form id={"RecoveryForm"} onSubmit={this.createNewPassword}>
                        <p>Crie uma senha nova para sua conta</p>
                        <Input inputColor={'inputGrey'} name={'password'} type={'password'} minlength={6} placeholder={'Digite sua senha'} onChange={this.handleChange}/>
                        <Input inputColor={'inputGrey'} name={'confirmPassword'} type={'password'} minlength={6} placeholder={'Confirme sua senha'} onChange={this.handleChange}/>
                        <div><Button name={'Criar nova senha'} buttonColor={'primary'}/></div>
                    </form>
                </ImgScreen>
            </Fragment>
        )
    }

}
