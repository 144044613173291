import React, { Component, Fragment } from "react";
import './input.scss'

export class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            typeInput: this.props.type
        }
    }

    render() {
        const {
            label,
            inputId,
            disabled,
            placeholder,
            name,
            type,
            size,
            defaultValue,
            inputStyle,
            inputColor,
            autofocus,
            onInput,
            onFocus,
            onBlur,
            onKeyPress,
            onKeyUp,
            onKeyDown,
            onChange,
            maxlength,
            minlength,
            value,
            _onFocus,
            _onBlur,
            required
        } = this.props;

        return (
            <Fragment>
                <div id="Input" className={inputColor}>
                    <label htmlFor={inputId}>{label}</label>
                    <input
                        ref={this.input}
                        type={this.state.typeInput}
                        name={name}
                        disabled={disabled}
                        id={inputId}
                        placeholder={placeholder}
                        size={size}
                        defaultValue={defaultValue}
                        value={value}
                        maxLength={maxlength}
                        minLength={minlength}
                        autoFocus={autofocus}
                        onInput={onInput}
                        onFocus={onFocus || _onFocus}
                        onBlur={onBlur || _onBlur}
                        onKeyPress={onKeyPress}
                        onKeyUp={onKeyUp}
                        onKeyDown={onKeyDown || null}
                        onChange={onChange || null}
                        required={required}
                        className={inputStyle}
                    />
                </div>
            </Fragment>
        )

    }
}