import React, { Component } from "react";
import './buttonIcon.scss'

export class ButtonIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const {
            onclick
        } = this.props;

        return (
            <button id="ButtonIcon" className="buttonIcon" onClick={onclick}>{this.props.children}</button>
        )

    }
}
