import React, { Component } from "react"
import { Link } from "react-router-dom"

import LogoDuee from '../../assets/duee.svg'
import BgPic from '../../assets/Leão-Duee-color.png'

import "./imgScreen.scss"

export class ImgScreen extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="gridLoginContainer">
                <div className='pic'>
                    <img src={BgPic} alt=""/>
                </div>
                <div className="form">
                    <div className='login'>
                        <Link to={'/'}><img src={LogoDuee} alt=""/></Link>
                        <div className="content">{this.props.children}</div>
                    </div>
                </div>
            </div>
        )
    }
}
