import React, { Component } from "react";
import './bigCard.scss'

export class BigCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            name,
            buttonColor
        } = this.props;

        return (
            <div id="BigCard" className={buttonColor}>
                {this.props.children}
            </div>
        )

    }
}