import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyDwhJHshGKhRgQNLz9y8vRn7bJmGHEvKCM",
    authDomain: "duee-system-8181d.firebaseapp.com",
    databaseURL: "https://duee-system-8181d.firebaseio.com",
    projectId: "duee-system-8181d",
    storageBucket: "duee-system-8181d.appspot.com",
    messagingSenderId: "183041947098",
    appId: "1:183041947098:web:60726b16bce65143eafce3"
};

export const firebaseImpl = firebase.initializeApp(config);
export const firebaseValue = firebase;
export const firebaseDatabase = firebase.firestore();
export const firebaseStorage = firebase.storage();
export const firebaseAuth = firebase.auth();