import React, { Component, Fragment } from "react";
import './uploadButton.scss'

export class UploadButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            typeInput: this.props.type
        }
    }

    render() {
        const {
            name,
            onchange
        } = this.props;

        return (
            <Fragment>
                <input type="file" name={name} id={'UploadButton'} className={'custom-file-input'} onChange={onchange} multiple/>
            </Fragment>
        )

    }
}
