import React, { Component } from "react"
import { NavLink  } from "react-router-dom"
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { firebaseAuth } from "../../app/firebase-utils";

import { Input } from '../../components/input/input'
import { Button } from '../../components/button/button'
import { ButtonIcon } from '../../components/buttonIcon/buttonIcon'
import { faBars, faUserShield, faCloud } from '@fortawesome/free-solid-svg-icons'
import { faUser, faBell } from '@fortawesome/free-regular-svg-icons'

import LogoDuee from '../../assets/duee.svg'

import "./navbar.scss"
import userPhoto from '../../assets/facebook-batman.jpg'

export class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state ={
            userDropActive: false,
            notificationDropActive: false,
            userInfo: JSON.parse(localStorage.getItem('user-info')),
            changeSystem: false
        }
    }

    showUserDrop = () => {
        if(this.state.notificationDropActive || this.state.changeSystem) { this.setState({notificationDropActive: !this.state.notificationDropActive}); }
        if(this.state.changeSystem) { this.setState({changeSystem: !this.state.changeSystem}); }
        this.setState({userDropActive: !this.state.userDropActive});

        setTimeout(() => {
            if(this.state.userDropActive) {
                this.setState({userDropActive: false});
            }
        }, 6000)
    };

    showNotificationDrop = () => {
        if(this.state.userDropActive) { this.setState({userDropActive: !this.state.userDropActive}); }
        if(this.state.changeSystem) { this.setState({changeSystem: !this.state.changeSystem}); }
        this.setState({notificationDropActive: !this.state.notificationDropActive});

        setTimeout(() => {
            if(this.state.notificationDropActive) {
                this.setState({notificationDropActive: false});
            }
        }, 6000)
    };

    logout = () => {
        firebaseAuth.signOut()
            .then(() => {
                localStorage.clear();
                window.location.href = '/';
            })
            .catch(e => console.log(e))
    };

    returnRole = (info) => {
        switch (info) {
            case 'admin':
                return 'Administrador'
                break;
            case 'func':
                return 'Funcionário'
                break;
            case 'cli':
                return 'Cliente'
                break;
        }
    };

    changeSystem = () => {
        if(this.state.userDropActive) { this.setState({userDropActive: !this.state.userDropActive}); }
        if(this.state.notificationDropActive) { this.setState({notificationDropActive: !this.state. notificationDropActive}); }
        this.setState({changeSystem: !this.state.changeSystem});

        setTimeout(() => {
            if(this.state.changeSystem) {
                this.setState({changeSystem: false});
            }
        }, 6000)
    };

    render() {
        const {
            title,
        } = this.props;

        return (
            <nav id="Navbar">
                <div className="imageContainer">
                    <img src={LogoDuee} alt="Logo Duee"/>
                    <h1>{ title }</h1>
                </div>
                <div className="searchContainer">
                    {/*<div><Input placeholder={'Pesquisa'} inputColor={'inputGrey'} type={'text'}/></div>*/}
                </div>
                <div className="infosContainer">
                    {window.screen.width > 768 ?
                        <div>
                            {this.state.userInfo.role === 'cli' ? <Button name="DueeDrive" buttonColor={'secondary'}/> : <Button name="Duee Admin"  onclick={this.changeSystem} buttonColor={'secondary'}/>}
                            <ButtonIcon onclick={this.showNotificationDrop}><FontAwesomeIcon icon={faBell} /></ButtonIcon>
                            <ButtonIcon onclick={this.showUserDrop}><FontAwesomeIcon icon={faUser} /></ButtonIcon>
                            {this.state.notificationDropActive ? <div id={'NotificationDrop'}><h1>Em Breve<br/> Notificações</h1></div> : null}
                            {this.state.userDropActive ?
                                <div id={'UserDrop'}>
                                    <div>
                                        <img src={userPhoto} alt="User Photo"/>
                                        <h3>{this.state.userInfo.name}</h3>
                                        <p>{this.returnRole(this.state.userInfo.role)}</p>
                                    </div>
                                    <div>
                                        <Button name={'Logout'} buttonColor={'primary'} onclick={this.logout}/>
                                    </div>
                                </div>
                                : null}

                            {   this.state.changeSystem ?
                                <div id={'SystemChangeDrop'}>
                                    <ul>
                                        <li><NavLink to="/dashboard/admin" activeClassName="active"><FontAwesomeIcon icon={faUserShield}/>Duee Admin</NavLink></li>
                                        <li><NavLink to="/dashboard/drive" activeClassName="active"><FontAwesomeIcon icon={faCloud}/>Duee Drive</NavLink></li>
                                    </ul>
                                </div>:null
                            }
                        </div>
                    :
                        <ButtonIcon>
                            <FontAwesomeIcon icon={faBars} />
                        </ButtonIcon>
                    }
                </div>
            </nav>
        )
    }
}
