import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Routes from './Routes'
import './App.scss';

function App() {
  return (
      <BrowserRouter>
          <Helmet>
              <link href="https://fonts.googleapis.com/css?family=Oswald&display=swap" rel="stylesheet"/>
          </Helmet>
          <ToastContainer />
        <Routes/>
      </BrowserRouter>
  );
}

export default App;
