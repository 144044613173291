import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'


import './smallCard.scss'
import Modal from "../modal/modal";
import { Button } from "../button/button";
import { firebaseDatabase, firebaseStorage } from "../../app/firebase-utils";
import { toast } from "react-toastify";


export class SmallCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selected: false,
            favorite: false,
            blocked: false,
            userSelectedUid: '',
            userSelectedInfo: {},
            userlogedUid: JSON.parse(localStorage.getItem('uid')),
            showDelete: false
        };
    }

    showModal = e => {
        this.setState({
            show: !this.state.show,
            selected: !this.state.selected
        });
    };

    showModalDelete = e => {
        this.setState({
            showDelete: !this.state.showDelete,
            selected: !this.state.selected
        });
    };

    selectUser = () => {
        this.setState({ selected: !this.state.selected });
        this.showModal();
    };

    getUser = () => {
        firebaseDatabase.collection('users').where("uid", "==", this.props.userDataProps.uid).get()
            .then((e) => {
                e.forEach((doc) => {
                    this.setState({ userSelectedUid: doc.id, blocked: doc.data().blocked, userSelectedInfo: doc.data() });
                });
            })
            .catch((e) => console.log(e));
    };

    deleteFileStorage = async (namefile) => {
        let storageRef = firebaseStorage.ref(`clientsFiles/${namefile}`);

        await storageRef.delete()
            .then(() => {
                this.setState({ loading: false });
                toast.success('Arquivo deletado com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(e => {
                console.log(e)
                this.setState({ loading: false });
                toast.error('Ops! Aconteceu algo de errado ao deletar o arquivo', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
    }

    deleteUser = (e) => {

        e.preventDefault();

        if (this.props.userDataProps.uid === this.state.userlogedUid) {
            return toast.error('Você não pode se bloquear!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

        if (localStorage.getItem('user-role') === 'func' && this.props.userDataProps.role === 'admin') {
            return toast.error('Você não tem permissão para alterar o status de um Administrador!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

        return firebaseDatabase.collection('users').where("empresa", "==", this.props.userDataProps.empresa).get()
            .then((res) => {

                console.log(res.docs.length)

                if (res.docs.length <= 1) {
                    //todo o role de deletar tudo
                    console.log("deletar tudo do usuario")

                    firebaseDatabase.collection('companyDrive').where("company", "==", this.props.userDataProps.empresa).get()
                        .then(async res => {
                            this.companyDriveID = res.docs[0].id
                            const companylist = res.docs[0].data()

                            if (companylist.fileList.length > 0) {
                                companylist.fileList.forEach(file => {
                                    this.deleteFileStorage(file.name)
                                })
                            }

                        }).then(() => firebaseDatabase.collection('companyDrive').doc(this.companyDriveID).delete())
                        .then(res => {
                            console.log(res)
                            toast.success('Drive excluido com sucesso.', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            })

                        }).then(() => firebaseDatabase.collection('company').where("name", "==", this.props.userDataProps.empresa).get())
                        .then(res => {
                            this.companyID = res.docs[0].id

                            firebaseDatabase.collection('company').doc(this.companyID).delete()
                        })
                        .then(res => {
                            console.log(res)
                            toast.success('Empresa excluido com sucesso.', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            })

                        }).then(() => firebaseDatabase.collection('users').doc(this.state.userSelectedUid).delete())
                        .then(res => {
                            console.log(res)
                            toast.success('Usuário excluido com sucesso.', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                onClose: () => window.location.reload()
                            });
                        }).catch((err) => {
                            console.log(err)
                            toast.error('Error ao excluir usuário!', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                        })
                } else {
                    //deleta só o usuario
                    console.log("deletando user")
                    firebaseDatabase.collection('users').doc(this.state.userSelectedUid).delete()
                        .then(res => {
                            console.log(res)
                            toast.success('Usuário excluido com sucesso.', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                onClose: () => window.location.reload()
                            });
                        }).catch((err) => {
                            console.log(err)
                            toast.error('Error ao excluir usuário!', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                        })

                }
            })
            .catch(e => {
                console.log(e);
                toast.error('Error procurar usuário!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });

    };

    returnRole = (info) => {
        switch (info) {
            case 'admin':
                return 'Administrador'
                break;
            case 'func':
                return 'Funcionário'
                break;
            case 'cli':
            default:
                return 'Cliente'
                break;
        }
    };

    returnBlocked = (blocked) => {
        switch (blocked) {
            case true:
                return 'Sim'
                break;
            case false:
            default:
                return 'Não'
                break;
        }
    };

    componentDidMount() {
        this.getUser();
    }

    render() {
        const {
            cardName,
            cardType,
            userDataProps
        } = this.props;

        return (
            <Fragment>
                <Modal show={this.state.show} onClose={this.showModal} title={'Detalhes do Usuário'}>
                    <div style={{ width: 500 }}>
                        <p>Nome: <b>{this.state.userSelectedInfo.name}</b></p>
                        <p>Empresa: <b>{this.state.userSelectedInfo.empresa}</b></p>
                        <p>E-mail: <b>{this.state.userSelectedInfo.email}</b></p>
                        <p>Permissão: <b>{this.returnRole(this.state.userSelectedInfo.role)}</b></p>
                    </div>
                </Modal>

                <Modal show={this.state.showDelete} onClose={this.showModalDelete} title={'Excluir Usuário'}>
                    <div style={{ width: 500 }}>
                        <h2>Tem certeza que quer excluir este usuário?</h2>
                        <p>Nome: <b>{this.state.userSelectedInfo.name}</b></p>
                        <p>Empresa: <b>{this.state.userSelectedInfo.empresa}</b></p>
                        <p>E-mail: <b>{this.state.userSelectedInfo.email}</b></p>
                        <p>Permissão: <b>{this.returnRole(this.state.userSelectedInfo.role)}</b></p>
                        <div style={{ textAlign: 'center' }}>
                            <Button name={'Apagar'} buttonColor={'primary'} onclick={this.deleteUser} />
                            <Button name={'Cancelar'} buttonColor={'text'} onclick={this.showModalDelete} />
                        </div>
                    </div>
                </Modal>

                <div id="SmallCard" onClick={this.selectUser} className={this.state.selected ? 'selected  ' : null}>
                    <div>
                        <div><h3>{cardName}</h3></div>
                        <div><h3>{cardType}</h3></div>
                        <div><h3>{userDataProps.empresa}</h3></div>
                        <div>
                            <div className='listAction'>
                                {/*<div onClick={this.editModal}><FontAwesomeIcon icon={faEdit}/></div>*/}
                                <div onClick={this.showModalDelete}><FontAwesomeIcon icon={faTrashAlt} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
