class Auth {
    constructor() {
        this.authenticated = false
    }

    login() {
        if(localStorage.getItem('uid')) {
            this.authenticated = true;
        }
    }

    logout() {
        this.authenticated = false;
    }

    isAuthenticated() {
        return this.authenticated;
    }
}

export default new Auth();
