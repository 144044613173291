import React from "react"
import './loading.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export const Loading = () => (
    <div id={'Loading'}>
        <div><FontAwesomeIcon icon={faSpinner} size={'3x'}/></div>
        <div/>
    </div>
);