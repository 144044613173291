import React, { Component } from "react"
// import { Link } from "react-router-dom"
import Helmet from 'react-helmet'
import Auth from '../../app/auth'
import { firebaseStorage, firebaseDatabase, firebaseValue } from '../../app/firebase-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { UploadButton } from '../../components/uploadButton/uploadButton'
import { FileCard } from '../../components/fileCard/fileCard'
import { Button } from '../../components/button/button'
import { Navbar } from '../../components/navbar/navbar'
import Modal from '../../components/modal/modal'

import "./ftp.scss"
import { Loading } from "../../components/loading/loading";
import { Input } from "../../components/input/input";
import { toast } from "react-toastify";

export default class Ftp extends Component {
    constructor(props) {
        super(props);
        Auth.login();
        this.header = {
            title: "DueeDrive"
        };
        this.state = {
            userLogged: JSON.parse(localStorage.getItem('user-info')),
            show: false,
            loading: false,
            file: [],
            uploading: false,
            update: false,
            nameSelected: [JSON.parse(localStorage.getItem('user-info')).empresa],
            listCompany: [],
            fileArray: [],
            originalFileArray: [],
            clientArray: [],
            urlFile: '',
            modalContent: 0,
            companyID: '',
            companiesSelected: []
        }
    }

    showModal = e => {
        this.setState({
            show: !this.state.show
        });
    };

    handleChange = (e) => {
        // console.log(this.state.listCompany);

        let files = [...e.target.files];

        if (files.length > 0) {

            let arrayFiles = []

            console.log(files)
            files.forEach(file => {
                const urlFile = URL.createObjectURL(file);
                arrayFiles.push({ file: file, urlFile: urlFile })
            })
            // // this.setState({ file: arrayFiles, urlFile: urlFile });
            this.setState({ file: arrayFiles });

            this.openModalUpload()
        }
    };

    openModalUpload = () => {
        this.setState({ modalContent: 1, modalHeader: 'Upload de documento' });
        this.setState({ show: true });
    };

    openModalChangeCompany = () => {
        this.setState({ modalContent: 0, modalHeader: 'Lista de clientes' });
        this.setState({ show: true });
    };

    changeComapny = (e) => {
        let name = e.target.innerHTML;
        this.setState({ nameSelected: [name] });
        this.getCompanyFileList(name);
    };

    getAllCompany = () => {
        let array = [];
        let list = []
        firebaseDatabase.collection('company').get()
            .then(querySnapshot => {
                querySnapshot.forEach(obj => {
                    array.push(obj.data());
                });
                array.forEach(item => {
                    const i = { value: item.name, label: item.name }
                    return list = [...new Set([...list, i])]
                })
                this.setState({ listCompany: array, companiesSelected: list })
            })
            .catch(e => console.log(e));
    };

    uploadFile = (file) => {
        this.setState({ loading: true, uploading: true });

        //create storage ref
        let storageRef = firebaseStorage.ref(`clientsFiles/${file.name}`);

        //upload file
        let task = storageRef.put(file);

        //progress bar

        task.on('state_changed',
            (snapshot) => {
                let progressor = document.getElementById('ProgressBar');
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                progressor.value = progress;
                console.log('Upload is ' + progress + '% done');
            },
            (e) => { this.setState({ loading: false }); console.log(e) },
            () => {
                firebaseStorage.ref('clientsFiles').child(file.name).getDownloadURL()
                    .then(url => {
                        toast.success('Arquivo salvo com sucesso!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });

                        this.saveFileList(file, url)

                    })
                    .catch(e => {
                        this.setState({ loading: false });
                        console.log(e)
                    })
            }

        )
    };

    getFilesList = () => {
        this.setState({ uploading: false });
        //let uid = JSON.parse(localStorage.getItem('uid'));

        firebaseDatabase.collection('companyDrive').where('company', '==', this.state.nameSelected[0]).get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => {
                    this.setState({ companyID: doc.id })
                    // if (!this.state.update) {
                    //     this.saveFileList(doc.id);
                    // } else {
                    this.setState({ loading: false });
                    // this.getUserFileList();
                    this.setState({ show: false });
                    toast.success('Arquivo atualizado com sucesso!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    // }
                })
            })
            .catch(e => {
                this.setState({ loading: false });
                console.log(e)
            })
    };

    saveFileList = (fileSaved, url) => {
        let file = {
            name: fileSaved.name,
            url,
            type: fileSaved.type,
            lastModified: fileSaved.lastModified,
            uploaded: Date.now()
        }

        const newFilwList = this.state.file.filter(fileListed =>
            fileListed.file.name !== file.name
        )

        this.state.nameSelected.forEach(company => {

            let companyID = ''
            let fileList = []

            firebaseDatabase.collection('companyDrive').where('company', '==', company).get()
                .then(async querySnapshot => {
                    await querySnapshot.forEach(async (doc) => {
                        companyID = doc.id
                        fileList = doc.data().fileList
                        await fileList.push(file)
                    })

                    firebaseDatabase.collection('companyDrive').doc(companyID).update({ 'fileList': fileList })
                        .then(() => {
                            this.setState({ loading: false, file: newFilwList });

                            if (newFilwList.length < 1) this.getCompanyFileList(this.state.nameSelected[0]);
                        })
                }).catch(e => {
                    this.setState({ loading: false });
                    console.log(e)
                })
        })


    };

    getCompanyFileList = (companyToGet) => {
        let company = companyToGet;

        this.setState({ loading: true });

        firebaseDatabase.collection('companyDrive').where('company', '==', company).get()
            .then(querySnapshot => {
                this.setState({ loading: false });

                querySnapshot.forEach((doc) => {
                    let array = doc.data().fileList;
                    this.setState({ fileArray: array, companyID: doc.id, show: false });
                    // console.log(array);
                })
            })
            .catch(e => {
                this.setState({ loading: false });
                console.log(e)
            })
    };

    getAllClients = () => {
        let array = [];
        firebaseDatabase.collection('users').where('role', '==', 'cli').get()
            .then(obj => {
                obj.forEach(doc => {
                    array.push(doc.data());
                });
                // console.log(array);
                this.setState({ clientArray: array });
            })
            .catch(e => {
                console.log(e)
            })

    };

    filterName = (e) => {
        let value = e.target.value;

        if (!value) {
            this.getCompanyFileList(this.state.nameSelected[0])
        }

        let filtered = this.state.fileArray.filter(function (obj) {
            return obj.name.includes(value);
        });

        this.setState({ fileArray: filtered });
        //console.log(filtered)
    };

    selectCompanies = (e) => {

        let list = []

        if (!!e) {

            e.map(item =>
                list = [...new Set([...list, item.value])])

            this.setState({ nameSelected: list })

        } else {

            this.setState({ nameSelected: [] })
        }


    }

    componentDidMount() {
        console.log(this.state.nameSelected);

        this.getCompanyFileList(this.state.nameSelected[0]);

        if (this.state.userLogged.role === 'admin' || this.state.userLogged.role === 'func') {
            this.getAllCompany();
        }
    }

    render() {
        const style = {
            imgModal: {
                marginTop: '15px',
                minWidth: '400px',
                width: '100%',
                height: '350px',
                objectFit: 'contain'
            },
            btnContainer: {
                marginTop: '15px',
                textAlign: 'center'
            },
            selectContainer: {
                margin: '10px 0'
            },
            selectInput: {
                padding: '10px 0',
                borderRadius: '8px',
                minWidth: '195px'
            },
            loading: {
                margin: '10px 0'
            }
        };

        return (
            <div id={'Ftp'}>
                <Helmet title={this.header.title} />
                {this.state.loading && <Loading />}
                <Modal show={this.state.show} onClose={this.showModal} title={this.state.modalHeader}>

                    {this.state.modalContent === 0 ?
                        <div id={'CompanyList'}>
                            <ul className={'list'}>
                                {this.state.listCompany.map((object, index) => <li key={index} onClick={this.changeComapny} value={object.name} className={this.state.nameSelected[0] === object.name ? 'itemList actived' : 'itemList'}>{object.name}</li>)}
                            </ul>
                        </div>
                        :
                        <div>
                            <div style={style.selectContainer}>
                                <Select options={this.state.companiesSelected} isMulti defaultValue={[this.state.companiesSelected[this.state.companiesSelected.findIndex(obj => obj.value === this.state.nameSelected[0])]]} onChange={(e) => this.selectCompanies(e)} />
                            </div>
                            {<div style={style.loading}><progress id={'ProgressBar'} value='0' max='100' /></div>}
                            {this.state.file.map((file, i) => {
                                return <div key={i}>
                                    <img style={style.imgModal} src={file.urlFile} alt="Uploaded" />
                                    <div style={style.btnContainer}>
                                        <div><Button name={'Salvar no Drive'} buttonColor={'primary'} onclick={() => this.uploadFile(file.file)} /></div>
                                    </div>
                                </div>
                            })}
                        </div>
                    }
                </Modal>

                <Navbar />
                <div className={'content'}>
                    <div className='searchContainer'>
                        <div><Input placeholder={'Pesquisa'} inputColor={'inputGrey'} type={'text'} onChange={this.filterName} /></div>
                    </div>
                    <div className={'header'}>
                        <div>{this.state.userLogged.role === 'cli' ? <h1>{this.state.nameSelected[0]} Arquivos</h1> : <div onClick={this.openModalChangeCompany} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}><h1>{this.state.nameSelected[0]} Arquivos</h1><div style={{ margin: '0 10px' }}><FontAwesomeIcon icon={faSortDown} size={'1x'} /></div></div>}</div>
                        <div>{this.state.userLogged.role === 'cli' ? null : <UploadButton onchange={this.handleChange} />}</div>
                    </div>
                    <div className={'list-files'}>
                        {this.state.fileArray.length ? this.state.fileArray.sort((a, b) => b.uploaded - a.uploaded)
                            .map((file, index) =>
                                <FileCard key={index} cardName={file.name} cardType={file.type} urlIcon={file.url} cardData={file.uploaded} companyName={this.state.nameSelected[0]} />)
                            : <h1>Nenhum arquivo encontrado</h1>}
                    </div>
                </div>
            </div>
        )
    }
}
