import React, { Component } from "react";
import './button.scss'

export class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            typeInput: this.props.type
        }
    }

    render() {
        const {
            name,
            buttonColor,
            onclick
        } = this.props;

        return (
            <button id="Button" className={buttonColor} onClick={onclick}>{name}</button>
        )

    }
}
