import React, { Component, Fragment } from "react"
// import { Link } from "react-router-dom"
import Helmet from 'react-helmet'

import { Input } from '../../components/input/input'
import { Button } from '../../components/button/button'
import { ImgScreen } from '../../components/imgScreen/imgScreen'

import "./forgotPassword.scss"

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.header = {
            title: "Esqueci a senha"
        }
    }

    render() {
        return (
            <Fragment id="gridLoginContainer">
                <Helmet title={this.header.title}/>
                <ImgScreen>
                    <form id={"RecoveryForm"} action="">
                        <p>Digite seu E-mail para enviarmos uma menssagem de recuperação.</p>
                        <Input inputColor={'inputGrey'} type={'email'} placeholder={'Email'}/>
                        <div><Button name={'recuperar senha'} buttonColor={'primary'}/></div>
                    </form>
                </ImgScreen>
            </Fragment>
        )
    }
}