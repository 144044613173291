import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Auth from './auth'

export const PrivateRoute = ({ component: Component, ...rest}) => {
    return(
        <Route
            {...rest}
            render={props =>( localStorage.getItem('uid') ? <Component {...props}/> : <Redirect to='/'/> )}
        />
    )
};
