import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { PrivateRoute } from './private-route'

import Login from "../pages/login/login"
import ForgotPassword from "../pages/forgotPassword/forgotPassword"
import DashboardAdmin from "../pages/dashboardAdmin/dashboardAdmin"
import Ftp from "../pages/ftp/ftp"
import PasswordReset from "../pages/newPassword/newPassword"

export default props =>
    <Router>
        <Route exact path='/' component={Login} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <PrivateRoute exact path='/dashboard/admin' component={DashboardAdmin} />
        <PrivateRoute exact path='/dashboard/drive' component={Ftp} />
        <PrivateRoute exact path='/password/reset' component={PasswordReset} />
    </Router>
