import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt, faFilePdf, faFileArchive } from '@fortawesome/free-regular-svg-icons'


import './fileCard.scss'
import Modal from "../modal/modal";
import { Button } from "../button/button";
import { firebaseDatabase, firebaseStorage, firebaseValue } from "../../app/firebase-utils";
import { toast } from "react-toastify";
import { Loading } from "../loading/loading";

export class FileCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLogged: JSON.parse(localStorage.getItem('user-info')),
            show: false,
            selected: false,
            loading: false,
            userlogedUid: JSON.parse(localStorage.getItem('uid')),
            fileSelected: null,
            modalContent: 1,
            modalTitle: ''
        };
    }

    deleteFileStorage = () => {
        this.setState({ loading: true });
        //delete file
        let storageRef = firebaseStorage.ref(`clientsFiles/${this.props.cardName}`);

        storageRef.delete()
            .then(() => {
                this.setState({loading: false});
                this.getFilesList()
                toast.success('Arquivo deletado com sucesso!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false  ,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(e => {
                this.setState({loading: false});
                toast.error('Ops! Aconteceu algo de errado ao salvar o arquivo', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false  ,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
    };

    getFilesList = () => {
        this.setState({ uploading: false });

        firebaseDatabase.collection('companyDrive').where('company', '==', this.props.companyName).get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => {

                    doc.data().fileList.map(o =>
                        o.url === this.props.urlIcon &&
                        this.deleteFromList(doc.id, o)
                    );
                    // this.deleteFromList(doc.id);
                })
            })
            .catch(e => {
                this.setState({ loading: false });
                console.log(e)
            })
    };

    deleteFromList = (id, file) => {
        let itemDelete = firebaseValue.firestore.FieldValue.arrayRemove(file);

        firebaseDatabase.collection('companyDrive').doc(id).update({ 'fileList': itemDelete })
            .then(querySnapshot => {
                this.setState({ loading: false });
                this.showModal();
                // window.location.reload()
            })
            .catch(e => {
                this.setState({ loading: false });
                console.log(e)
            })
    };

    showModal = e => {
        this.setState({
            show: !this.state.show
        });
        // console.log(this.state.file)
    };

    showFile = () => {
        this.setState({ modalContent: 1, modalTitle: this.props.cardName })
        this.showModal()
    }

    typeOfFile = () => {
        const style = {
            container: { width: 500, textAlign: 'center' },
            img: {
                objectFit: 'fill',
                maxWidth: '500px',
                maxHeight: '400px'
            },
            icon: {
                margin: '40px 0',
                color: '#d82f5a'
            }
        }
        switch (this.props.cardType) {
            case 'image/png':
            case 'image/jpeg':
                return (
                    <div style={style.container}>
                        <img style={style.img} src={this.props.urlIcon} />
                    </div>
                )
                break
            case 'application/pdf':
                return (
                    <div style={style.container}>
                        <div style={style.icon}><FontAwesomeIcon icon={faFilePdf} size="3x" /></div>
                        {/* <iframe src={this.props.urlIcon} width="600" height="780"></iframe> */}
                    </div>
                )
                break
            case 'application/zip':
                return (
                    <div style={style.container}>
                        <div style={style.icon}><FontAwesomeIcon icon={faFileArchive} size="3x" /></div>
                        {/* <iframe src={this.props.urlIcon} width="600" height="780"></iframe> */}
                    </div>
                )
                break
            case 'video/mp4':
                return (
                    <div style={style.container}>
                        <video width="500" height='300' controls>
                            <source src={this.props.urlIcon} type="video/mp4" />
                            Your browser does not support HTML5 video.
                        </video>
                    </div>
                )
                break
            default:
                return (<h3>nenhum arquivo</h3>)
        }
    }

    deleteFile = () => {
        this.setState({ modalContent: 2, modalTitle: 'Apagar o arquivo' })
        this.showModal()
    }

    downloadFile = () => {
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function (event) {
            let blob = xhr.response;
        };
        xhr.open('GET', this.props.urlIcon);
        xhr.send();
    };

    render() {
        const {
            cardName,
            cardType,
            urlIcon,
            cardData
        } = this.props;

        let fileData = new Date(cardData);
        // console.log(urlIcon);
        return (
            <Fragment>
                {this.state.loading ? <Loading /> : null}

                <Modal show={this.state.show} onClose={this.showModal} title={this.state.modalTitle}>
                    {this.state.modalContent === 1 ?
                        this.typeOfFile()
                        :
                        <div style={{ width: 500 }}>
                            <p>Voce deseja apagar esse arquivo?</p>
                            <div style={{ textAlign: 'center' }}>
                                <Button name={'Apagar'} buttonColor={'primary'} onclick={this.deleteFileStorage} />
                                <Button name={'Cancelar'} buttonColor={'text'} onclick={this.showModal} />
                            </div>
                        </div>
                    }
                </Modal>

                <div id="FileCard" className={this.state.selected ? 'selected  ' : null}>
                    <div>
                        <div onClick={this.showFile} className='infos'>
                            <div><h3>{cardName}</h3></div>
                            <div><h3>{cardType}</h3></div>
                            <div><h3>{cardData ? `${fileData.getDate()}/${fileData.getMonth() + 1}/${fileData.getFullYear()}` : "--"}</h3></div>
                        </div>
                        <div>
                            <div className='listAction'>
                                {/* <div onClick={this.showFile}><FontAwesomeIcon icon={faDownload}/></div> */}
                                <div><a href={urlIcon} target='_blank' rel="noopener noreferrer" download><FontAwesomeIcon icon={faDownload} /></a></div>
                                {this.state.userLogged.role === 'cli' ? null : <div onClick={this.deleteFile}><FontAwesomeIcon icon={faTrashAlt} /></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
