import React, { Component } from "react"
import { Link } from "react-router-dom"
import Helmet from 'react-helmet'
import Auth from '../../app/auth'
import {firebaseAuth, firebaseDatabase} from '../../app/firebase-utils'

import { BigCard } from '../../components/bigCard/bigCard'
import { TitleCards } from '../../components/titleCards/titleCards'
import { SmallCard } from '../../components/smallCard/smallCard'
import { Button } from '../../components/button/button'
import { Navbar } from '../../components/navbar/navbar'
import Modal from '../../components/modal/modal'
// import FormUserModal from '../../components/formUserModal/formUserModal'

import "./dashboardAdmin.scss"
import {Loading} from "../../components/loading/loading";
import {Input} from "../../components/input/input";
import {toast} from "react-toastify";

export default class DashboardAdmin extends Component {
    constructor(props) {
        super(props);
        Auth.login();
        this.header = {
            title: "Dashboard"
        };
        this.state = {
            show: false,
            users: [],
            usersOriginal: [],
            admCompany: {name: 'Duee Brasil'},
            listCompany: [],
            addNewCompany: false,
            selectedUser: {},
            email: '',
            nome: '',
            empresa: '',
            role: '',
            password: '',
            newEmpresa: '',
            loading: false
        }
    }

    showModal = e => {
        this.setState({
            show: !this.state.show
        });
    };

    getListUser = () => {
        firebaseDatabase.collection("users").get().then((querySnapshot) => {
            let usersArray = [];
            querySnapshot.forEach((doc) => {
                usersArray.push(doc.data())
            });
            this.setState({users: usersArray, usersOriginal: usersArray});
            this.setState({selectedUser: this.state.users[0].uid});
            // console.log(this.state.selectedUser)
        });
    };

    handleChange = (event) => {
        const name = event.target.name,
            value = event.target.value;
        this.setState({[name]: value});
    };

    register = (e) => {
        e.preventDefault();
        this.setState({loading: true});

        if((this.state.role === 'cli' && this.state.empresa === 'none') || (this.state.role === 'cli' && this.state.empresa === '')) {
            this.setState({loading: false});
            return toast.error('Selecione ou cadastre uma empresa!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false  ,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

        if(this.state.role === '') {
            this.setState({loading: false});
            return toast.error('⚠ Preencha todo cadastro!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

        if((this.state.email === '' && this.state.password === '') || !this.validateEmail(this.state.email)) {
            this.setState({loading: false});
            return toast.error('⚠ Preencha todo cadastro!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

        firebaseAuth.createUserWithEmailAndPassword(this.state.email, this.state.password)
            .then(cred => {
                this.setState({loading: false});

                toast.success('Usuário cadastrado com sucesso', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

                this.saveUserInfo(cred.user.uid);
                // this.createFileList(cred.user.uid);
                // $state.go('dashboard')
            })
            .catch(error => {
                this.setState({loading: false});

                if(error.code === 'auth/email-already-in-use') {
                    toast.error('Email já está em uso!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false  ,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            });
    };

    // createFileList = (userId) => {
    //     let fileList = {
    //         uid: userId,
    //         fileList: []
    //     };
    //
    //     firebaseDatabase.collection('fileList').add(fileList)
    //         .then(docRef => console.log(docRef.id))
    //         .catch(e => console.log(e))
    // };

    saveUserInfo = (userid) => {
        let userInfo = {
            name: this.state.nome,
            empresa: this.state.admCompany.name,
            email: this.state.email,
            firstLogin: true,
            uid: userid,
            imgUrl: '',
            role: this.state.role,
            blocked: false
        };

        if(this.state.role === 'cli') {
            if(this.state.addNewCompany) {
                this.setState({empresa: this.state.newEmpresa})
            }

            userInfo.empresa = this.state.empresa;
        }

        if(this.state.addNewCompany) {
            firebaseDatabase.collection('company').add({name: this.state.empresa})
                .then(o => console.log(o))
                .catch(e => console.log(e));

            firebaseDatabase.collection('companyDrive').add({fileList: [], company: this.state.empresa})
                .then(o => console.log(o))
                .catch(e => console.log(e));
        }

        firebaseDatabase.collection("users").add(userInfo)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

        if(this.state.role === 'admin' || this.state.role === 'func') {
            firebaseDatabase.collection('favorites').add({uid: userid, favoritesList: {}})
                .then((a) => {
                    this.showModal();
                    this.getListUser();
                    this.getAllCompany();
                })
                .catch((e) => console.log(e))
        }

        if(this.state.role === 'cli') {
            this.showModal();
            this.getListUser();
            this.getAllCompany();
        }
    };

    getAllCompany = () => {
        let array = [];
        firebaseDatabase.collection('company').get()
            .then(querySnapshot => {
                querySnapshot.forEach(obj => {
                    if(obj.data().name !== 'Duee Brasil') {
                        array.push(obj.data());
                    }
                    });
                this.setState({listCompany: array})
            })
            .catch(e => console.log(e))
    };

    validateEmail = (email) => {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    filterUser = (e) => {
        let value = e.target.value;

        if(!value) {
            return this.setState({users: this.state.usersOriginal})
        }

        let filtered =  this.state.users.filter(function(obj) {
            return obj.name.includes(value);
        });

        // this.setState({users: filtered});
        console.log(filtered)
    };

    returnRole = (info) => {
        switch (info) {
            case 'admin':
                return 'Administrador'
                break;
            case 'func':
                return 'Funcionário'
                break
            case 'cli':
                default:
                return 'Cliente'
        }
    };

    changedSelect = (e) => {
        let value = e.target.value;

        console.log(value);
        if(value === 'new') {
            this.setState({addNewCompany: true})
        }else{
            this.setState({addNewCompany: false})
        }

        this.setState({empresa: value})
    };

    componentDidMount() {
        this.getListUser();
        this.getAllCompany();
    }

    render() {
        const style ={
            select: {
                width: '100%',
                height: '36px',
                borderRadius: '8px',
                border: 'none',
                backgroundColor: '#dadada',
                color: '#6f6f6f',
                paddingLeft: '8px'
            }
        };
        return (
            <div id="dashboardAdmin">
                <Helmet title={this.header.title}/>
                <Modal show={this.state.show} onClose={this.showModal} title={'Cadastrar Usuário'}>
                    <div id={'FormUser'}>
                        {this.state.loading ? <Loading/> : null}
                        <form onSubmit={this.register}>
                            <div className={'form'}>
                                <div>
                                    { localStorage.getItem('user-role') === 'func' ? null : <div>
                                        <input type='radio' ref="terms" name='role' value="admin" id='admin' onChange={this.handleChange} required={true}/>
                                        <label htmlFor='admin'>Administrador</label>
                                    </div>}

                                    <div>
                                        <input type='radio' ref="terms" name='role' value="func" id='func' onChange={this.handleChange} required={true}/>
                                        <label htmlFor='func'>Funcionário</label>
                                    </div>

                                    <div>
                                        <input type='radio' ref="terms" name='role' value="cli" id='cli' onChange={this.handleChange} required={true}/>
                                        <label htmlFor='cli'>Cliente</label>
                                    </div>
                                </div>
                                <Input inputColor={'inputGrey'} placeholder={'Nome'} type={'text'} name={'nome'} onChange={this.handleChange}/>
                                {this.state.role === 'cli' ? <select name={'empresa'} style={style.select} onChange={this.changedSelect}>
                                    <option value="none">Selecionar Empresa</option>
                                    {this.state.listCompany.map((o, index) => <option key={index} value={o.name}>{o.name}</option>)}
                                    <option value="new">Adicionar Outra Empresa</option>
                                </select>: null}
                                {this.state.addNewCompany ? <Input inputColor={'inputGrey'} placeholder={'Nova Empresa'} type={'text'} name={'newEmpresa'} onChange={this.handleChange}/> : null}
                                <Input inputColor={'inputGrey'} placeholder={'E-mail'} type={'email'} name={'email'} onChange={this.handleChange}/>
                                <Input inputColor={'inputGrey'} placeholder={'Senha'} type={'password'} minlength={'6'} name={'password'} onChange={this.handleChange}/>
                            </div>
                            <div>
                                <Button name={'Cadastrar'} buttonColor={'primary'}/>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Navbar title={'Duee Admin'} role={'Administrador'} hist={this.props.history}/>
                <div className="main">
                    <div className='searchContainer'>
                        <div><Input placeholder={'Pesquisa'} inputColor={'inputGrey'} type={'text'} onChange={this.filterUser}/></div>
                    </div>
                    <div className='title'>
                        <div><h1>Lista de usuários</h1></div>
                        <div><Button name="Criar Usuário novo" buttonColor={'primary fullSize'} onclick={this.showModal}/></div>
                    </div>
                    <TitleCards title1={'Nome'} title2={'Tipo'} title3={'Empresa'} title4={'Opções'}/>
                    {this.state.users.map((user) => <SmallCard key={user.uid} cardName={user.name} cardType={this.returnRole(user.role)} userDataProps={user}/>)}
                </div>
                {/*<div className="right"><BigCard></BigCard></div>*/}
            </div>
        )
    }
}
